	{
		"behawioralna":
		{
			"short":
		  	{
			"name": "konsultacja behawioralna",
			"alt": "owczarek niemiecki użytkowy",
			"description": [
			  "Ciągnięcie na smyczy",
			  "Niszczenie przedmiotów",
			  "Zachowania lękowe",
			  "Zachowania agresywne"
			]
		  	},
			"long":
		  	{
			"name": "konsultacja behawioralna",
			"alt": "owczarek niemiecki użytkowy",
			"price": [
				"230zł/2h"
			],
			"shortDescription": "Konsultacja przeznaczona dla osób, których pies wykazuje znaczące problemy, dot. m.in.: ciągnięcia na smyczy, niszczenia przedmiotów, lęku separacyjnego, zachowań agresywnych, skakania, podgryzania itp.",
			"description": [
			  "spotkanie teoretyczno-praktyczne",
			  "analiza zachowania psa i zebranie wywiadu środowiskowego",
			  "wstępna diagnoza problemu oraz propozycja planu pracy i terapii",
			  "spotkania prowadzone w miejscu zamieszkania lub na spacerze",
			  "zalecenia oraz plan pracy w formie on-line",
			  "miesięczna opieka on-line",
			  "możliwość dalszej pracy w korzystnych pakietach szkoleniowych"
			]
		  	}
		},
		"wychowawcza":
		{
			"short":
		  	{
			"name": "konsultacja wychowawcza",
			"alt": "pies chart",
			"description": [
			  "Wychowanie",
			  "Psie potrzeby",
			  "Zasady domowe",
			  "Pierwsze treningi"
			]
		  	},
			"long":
		  	{
			"name": "konsultacja wychowawcza/ogólna",
			"alt": "pies chart",
			"price": [
				"180zł/2h"
			],
			"shortDescription": "Zajęcia dla osób chcących skonsultować swój przypadek ze specjalistą, choć pies nie przejawia wyraźnych zaburzeń. Obejmuje naukę poprawnego wprowadzenia szczenięcia do domu i odpowiedzialnego wychowania.",
			"description": [
			  "spotkanie teoretyczno-praktyczne",
			  "omówienie etapów rozwoju szczenięcia, potrzeb etologicznych i ich zaspokajania",			  
			  "wprowadzenie podstawowych zasad w codziennym życiu oraz pierwszych ćwiczeń",
			  "spotkania prowadzone w miejscu zamieszkania lub na spacerze",
			  "zalecenia oraz plan pracy w formie on-line",
			  "miesięczna opieka on-line",
			  "możliwość dalszej pracy w korzystnych pakietach szkoleniowych"
			]
		  	}
		},
		"indywidualne":
		{
			"short":
		  	{
			"name": "zajęcia indywidualne",
			"alt": "owczarek niemiecki z trenerką",
			"description": [
			  "Komunikacja z psem",
			  "Motywowanie psa",
			  "Nowe polecenia",
			  "Budowanie nawyków"
			]
		  	},
			"long":
		  	{
			"name": "zajęcia indywidualne",
			"alt": "owczarek niemiecki z trenerką",
			"price": [
				"100zł/45min",
				"pakiet 3 zajęć - 260zł",
				"pakiet 5 zajęć - 420zł"
			],
			"shortDescription": "Zajęcia dla psów i opiekunów, którzy nie mogą lub nie chcą pracować w grupie. Usługa skierowana głównie do osób, które skorzystały z konsultacji lub są świadomymi opiekunami potrzebującymi pomocy treningowej.",
			"description": [
			  "nauka prawidłowej komunikacji z psem",
			  "budowanie motywacji do pracy z opiekunem",
			  "nauka podstawowych poleceń tj.: chodzenia na luźnej smyczy, chodzenia przy nodze bez smyczy, przywołania, prawidłowej zabawy, samo/autokontroli itp.",
			  "materiał szkoleniowy dobierany indywidualnie",
			  "szkolenie oparte głównie na wzmocnieniu pozytywnym",
			  "pomoc w prowadzeniu dzienniczka treningowego"
			]
		  	}
		},
		"grupowe":
		{
			"short":
		  	{
			"name": "zajęcia grupowe",
			"alt": "owczarki staroniemieckie i nowofunland",
			"description": [
			  "Praca w rozproszeniu",
			  "Socjalizacja",
			  "Utrwalanie nawyków",
			  "Rozwój kompetencji"
			]
		  	},
			"long":
		  	{
			"name": "zajęcia grupowe",
			"alt": "owczarki staroniemieckie i nowofunland",
			"price": [
				"przedszkole - 200zł za 4 treningi",
				"podstawówka - 330zł za 5 treningów + wykład",
				"sportowy duet - 55zł od psa"
			],
			"shortDescription": "Praca w grupie skierowana jest do duetów, które mają zbudowany fundament relacji i komunikacji z psem i chcą rozwijać swoje kompetencje w trudniejszym środowisku.",
			"description": [
			  "<span class='service__expanded__text-strong'>PSIE PRZEDSZKOLE</span> - dostępne jest dla psiaków od 3 do 6 miesiąca życia. Dużą uwagę zwracam na poprawną komunikację, socjalizację oraz habituację zwierzęcia. Mniejszą wagę przywiązuję do nauki posłuszeństwa - w przedszkolu psiaki poznają świat oraz komunikację naturalną między rówieśnikami, starszymi psami oraz ludźmi.",
			  "<span class='service__expanded__text-strong'>PSIA PODSTAWÓWKA</span> - inaczej podstawy posłuszeństwa. Zajęcia dostępne są dla psiaków od 5 miesiąca, nie ma górnej granicy wieku. Podczas tych spotkań wzmacniamy nabyte już umiejętności oraz wprowadzamy nowe polecenia w trudniejszym środowisku. Uczymy się m.in. luźnej smyczy, przywołania, mijania się z innymi psami oraz grzecznego czekania.",
			  "<span class='service__expanded__text-strong'>SZKOLENIA SPORTOWE W DUECIE</span> -  Rally-O, NoseWork, Dogfitness lub pakiety mieszane. Zajęcia skierowane są do zespołów, które chcą rozwijać swoje kompetencje sportowe w większym rozproszeniu lub chcą spędzić czas na treningu z psem wspólnie ze znajomymi.",
			  "Obecność innych psów często utrudnia naukę od podstaw, dlatego uważam, że praca w grupie powinna być jednym z kolejnych kroków rozwoju umiejętności, które nabywane są w sposób indywidualny. "
			]
		  	}
		},
		"spacer":
		{
			"short":
		  	{
			"name": "Spacer socjalizacyjny",
			"alt": "owczarek niemiecki i toller z trenerką",
			"description": [
			  "Socjalizacja",
			  "Komunikacja",
			  "Swoboda",
			  "Nauka"
			]
		  	},
			"long":
		  	{
			"name": "Spacer socjalizacyjny",
			"alt": "owczarek niemiecki i toller z trenerką",
			"price": [
				"indywidualny 100zł/45min",
				"grupa 3-5 psów 30zł od psa"				
			],
			"shortDescription": "Spotkania mają na celu nauczyć psy poprawnej psiej komunikacji i odczytywania sygnałów niewerbalnych. Tym samym przewodnicy uczą się prawidłowo zarządzać smyczą i przestrzenią.",
			"description": [				
			  "spotkania przeznaczone dla wszystkich psiaków",
			  "możliwość spotkań w grupie jak i z jednym psem trenera",
			  "socjalizacja psów i budowanie dobrych relacji",
			  "wsparcie w terapii behawioralnej związanej z nieufnością/agresją do psów",
			  "nauka zarządzania smyczą poprzez przewodnika",
			  "spacer w spokojnych warunkach na dużych i pustych przestrzeniach",
			  "spełnianie naturalnej psiej potrzeby kontaktu oraz eksploracji"
			]
		  	}
		},
		"nosework":
		{
			"short":
		  	{
			"name": "nosework",
			"alt": "pies toller pracujący nosem",
			"description": [
			  "Poszukiwanie zapachu",
			  "Nauka skupienia",
			  "Wzmacnianie pewności",
			  "Ambicje sportowe"
			]
		  	},
			"long":
		  	{
			"name": "nosework",
			"alt": "pies toller pracujący nosem",
			"price": [
				"70zł/30min",
				"pakiet 5 spotkań - 300zł"
			],
			"shortDescription": "Nosework polega na wyszukiwaniu przez psa konkretnego zapachu ukrytego w różnych lokalizacjach, takich jak pojemniki, wnętrza, zewnętrza oraz pojazdy.",
			"description": [
			  "zapachy naturalne na zajęciach: cynamon, pomarańcza, goździk",
			  "intensywny wysiłek węchowy zdrowo męczy psią głowę",
			  "zajęcia idealne dla psów reaktywnych - nauka skupienia na zadaniu",
			  "trening węchowy jako forma budowania pewności siebie dla psów lękliwych",
			  "nauka “czytania psa” - zwracanie uwagi na najdrobniejsze sygnały prezentowane przez psa",
			  "przygotowanie do startów w zawodach"
			]
		  	}
		},
		"dogfitness":
		{
			"short":
		  	{
			"name": "Dogfitness",
			"alt": "opiekun z tollerem na sali zajęć",
			"description": [
			  "Świadomość ciała",
			  "Budowa mięśni",
			  "Wysiłek intelektualny",
			  "Trening fizyczny"
			]
		  	},
			"long":
		  	{
			"name": "Dogfitness",
			"alt": "opiekun z tollerem na sali zajęć",
			"price": [
				"70zł/45min",
				"pakiet 5 spotkań - 300zł"
			],
			"shortDescription": "Zajęcia wspomagające zdrowie Twojego czworonoga. Podczas treningów skupimy się na budowie mięśni oraz rozwoju koordynacji.",
			"description": [
			  "zajęcia przeznaczone dla wszystkich psiaków",
			  "trening dobrany indywidualnie na podstawie badania wstępnego oraz wywiadu",
			  "podczas pierwszego spotkania wspólnie ustalamy cel, do którego stopniowo dążymy i na bieżąco weryfikujemy efekty",
			  "rozwój konkretnych partii mięśniowych",
			  "wsparcie w rehabilitacji",
			  "świetna zabawa połączona z rozwojem świadomości ciała u psa",
			  "trening z wykorzystaniem sprzętów fitnessowych"
			]
		  	}
		},
		"rally-o":
		{
			"short":
		  	{
			"name": "Rally-O",
			"alt": "cocker spaniel angielski w  trakcie zajęć rally-o",
			"description": [
			  "Sportowe szkolenie",
			  "Rozwój umiejętności",
			  "Posłuszeństwo na luzie",
			  "Motywacja"
			]
		  	},
			"long":
		  	{
			"name": "Rally-O",
			"alt": "cocker spaniel angielski w  trakcie zajęć rally-o",
			"price": [
				"70zł/45min",
				"pakiet 5 spotkań - 300zł"				
			],
			"shortDescription": "Jeżeli Twój pies zna podstawy posłuszeństwa, kolejnym etapem może być rozpoczęcie sportowego szkolenia Rally-Obedience, czyli posłuszeństwa na luzie.",
			"description": [
			  "dalszy rozwój umiejętności związanych z posłuszeństwem",
			  "różnorodność ćwiczeń wykonywanych podczas pokonywania toru z zadaniami",
			  "zaznajomienie z regulaminem Stowarzyszenia Rally-O Polska",
			  "elementarna nauka poszczególnych znaków z danej klasy",
			  "budowanie ogólnej motywacji psa do treningu",
			  "przygotowanie do startu w zawodach"
			]
		  	}
		}		
	}
